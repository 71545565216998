<template>
  <v-form @submit.prevent="save">
    <h2 class="mt-0 mb-6">{{ headline }}</h2>
    <v-select
      outlined
      :items="kinds"
      v-model="attribute.kind"
      label="Typ"
    ></v-select>

    <v-text-field
      outlined
      v-model="attribute.value"
      label="Bezeichnung"
    ></v-text-field>

    <p>
      <small
        >Wenn du die Kategorie deaktivierst, ist diese nur für zukünftige Kampagnen nicht
        wählbar. Auf bereits bestehende Kampagnen in dieser Kategorie hat dies
        keine Auswirkung.</small
      >
    </p>

    <v-checkbox dense v-model="attribute.active" label="aktiv"></v-checkbox>

    <v-row class="mt-4">
      <v-spacer></v-spacer>
      <v-btn @click="cancel" type="button" text color="primary">{{ trl("cancel") }}</v-btn>
      <v-btn @click="save" color="primary">{{
        trl(buttonSubmitLabel)
      }}</v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { trl } from "@/utils/strings";
import { mapGetters } from "vuex";

export default {
  name: "AttributesForm",
  data() {
    return {
      headline: "",
      buttonSubmitLabel: "",
      kinds: ["GATTUNG", "SERVICE"],
      attribute: {},
    };
  },
  computed: {
    ...mapGetters(["current", "attributes"]),
  },
  methods: {
    getItem(id) {
      this.attribute = JSON.parse(
        JSON.stringify(
          this.attributes.find((item) => item.id == id) || {
            tenant_id: this.current.id,
            kind: null,
            value: null,
            active: 1,
          }
        ));

        this.headline = this.attribute.id ? "Kategorie bearbeiten" : "Neue Kategorie anlegen";
        this.buttonSubmitLabel = this.attribute.id ? "save changes" : "save";
    },
    cancel() {
      this.$router.replace("./");
    },
    async save() {
      this.attribute.active = this.attribute.active ? 1 : 0;

      await this.$store.dispatch("req", {
        route: "attributes",
        data: this.attribute,
      });
      this.$store.dispatch("req", {
        route: "attributes",
        mutation: "attributes",
      });
      this.$router.replace("./");
    },
    trl,
  },
  created() {
    this.getItem(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    this.getItem(to.params.id);
    next();
  },
};
</script>

<style>
</style>